import React, { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { BsCashCoin } from "react-icons/bs";
import { CgMoreVerticalAlt } from "react-icons/cg";
import {
  FaPlus,
  FaSearch,
  FaMoneyBillWave,
  FaHistory,
  FaClipboardList,
  FaTrashAlt,
} from "react-icons/fa";
import { GrEdit, GrHistory } from "react-icons/gr";
import { ImEnter } from "react-icons/im";

const Paginated = ({
  data,
  handleExplore,
  handlePayFees,
  handleRecordTest,
  handleFeesHistory,
  toggleContextMenu,
  setToggleContextMenu,
  handleCreate,
  handleUpdate,
  handleDelete,
  handlePayTRFees,
  handleRTHistory,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentCount, setStudentCount] = useState();

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    currentStudent: null,
  });

  const menuItems = [
    { label: "New Student", icon: <FaPlus />, action: handleCreate },
    {
      label: "Update",
      icon: <GrEdit />,
      action: handleUpdate,
    },
    {
      label: "Explore",
      icon: <FaSearch />,
      action: handleExplore,
      borderBottom: true,
    },

    { label: "Pay Fees", icon: <FaMoneyBillWave />, action: handlePayFees },
    {
      label: "Fees History",
      icon: <FaHistory />,
      action: handleFeesHistory,
      borderBottom: true,
    },
    { label: "Pay RT Fees", icon: <BsCashCoin />, action: handlePayTRFees },
    {
      label: "RT Fees History",
      icon: <GrHistory />,
      action: handleRTHistory,
      borderBottom: true,
    },
    {
      label: "Record Test",
      icon: <FaClipboardList />,
      action: handleRecordTest,
    },
    {
      label: "Delete",
      icon: <FaTrashAlt />,
      action: handleDelete,
      textClass: "text-red-600",
    },
  ];

  const handleToggleContextMenu = (e, student) => {
    e.preventDefault();
    setToggleContextMenu(!toggleContextMenu);
    setContextMenu({
      visible: true,
      x: e.pageX,
      y: e.pageY,
      currentStudent: student,
    });
  };

  // Filter the data based on the search term
  const filteredData = data.filter(
    (item) =>
      (item.fullname &&
        item.fullname.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.studentID &&
        item.studentID
          .toString()
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) ||
      (item.phone && item.phone.toString().includes(searchTerm)) ||
      (item.trn && item.trn.toString().includes(searchTerm)) ||
      (item.course &&
        item.course.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.duration && item.duration.toString().includes(searchTerm)) ||
      (item.dateRegistered &&
        item.dateRegistered.toString().includes(searchTerm))
  );

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );

  // Update the count whenever currentRecords changes
  useEffect(() => {
    setStudentCount(filteredData.length);
  }, [filteredData]);

  const totalPages = Math.ceil(filteredData.length / recordsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleRecordsPerPageChange = (e) => {
    setRecordsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleContextMenu = (e, student) => {
    e.preventDefault(); // prevent the default context menu
    setContextMenu({
      visible: true,
      x: e.pageX,
      y: e.pageY,
      currentStudent: student,
    });
  };

  const handleClickOutside = () => {
    setContextMenu({ ...contextMenu, visible: false });
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [contextMenu]);

  return (
    <div className="h-full pl-[10px] w-full">
      <div className="text-gray-400 mx-4 my-1 font-bold">
        #(~{studentCount}~)
      </div>
      {/* Search and Records per Page */}
      <div className="flex md:flex-row flex-col items-center md:gap-1 gap-3 w-full lg:justify-between md:justify-evenly justify-evenly">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            className="ml-2 border border-black rounded pr-[34px] text-sm p-[7px]"
            placeholder="Search records ... "
          />
          <BiSearch size={20} className="absolute right-2 top-2" />
        </div>
        <div
          title="Add Student"
          className="flex flex-wrap items-center gap-1 text-sm py-2 px-4 bg-gray-500  cursor-pointer rounded-lg text-white font-light animate-bounce"
          onClick={handleCreate}
        >
          <AiOutlinePlus size={20} />{" "}
          <span className="md:hidden font-semibold text-sm lg:flex hidden">
            New Student
          </span>
        </div>
        <div className="md:mr-5 mr-1 flex md:ml-1 ml-8 md:pr-4">
          <label className="text-sm font-semibold">
            Records per page:
            <input
              type="number"
              value={recordsPerPage}
              onChange={handleRecordsPerPageChange}
              min={1}
              className="ml-2 p-1 border border-black rounded max-w-[100px] pl-3"
            />
          </label>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto mt-4 md:pr-2 pr-1">
        <table className="min-w-full table-auto bg-white shadow-md rounded-lg text-sm">
          <thead>
            <tr className="bg-blue-500 text-white">
              <th className="px-4 py-2">#</th>
              <th className="px-4 py-2">Full Name</th>
              <th className="px-4 py-2">Postal Address</th>
              <th className="px-4 py-2">Phone</th>
              <th className="px-4 py-2">Course</th>
              <th className="px-4 py-2">Duration</th>
              <th className="px-4 py-2">Phone (Next of Kin)</th>
              <th className="px-4 py-2">Date Registered</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((student) => (
              <tr
                key={student.studentID}
                className="text-center hover:bg-slate-200"
                onContextMenu={(e) => handleContextMenu(e, student)}
              >
                <td className="px-4 py-2">{student.studentID}</td>
                <td className="px-4 py-2">{student.fullname}</td>
                <td className="px-4 py-2">{student.postalAddress}</td>
                <td className="px-4 py-2">{student.phone}</td>
                <td className="px-4 py-2">{student.course}</td>
                <td className="px-4 py-2">{student.duration}</td>
                <td className="px-4 py-2">
                  {student.StudentNextOfKins[0]?.phone}
                </td>
                <td className="px-4 py-2">{student.dateRegistered}</td>
                <td className="px-4 py-2 relative text-left flex items-center gap-1">
                  <ImEnter
                    size={20}
                    title="Go to profile"
                    className="cursor-pointer"
                    onClick={() => handleExplore(student)}
                  />
                  <CgMoreVerticalAlt
                    className="cursor-pointer md:hidden"
                    size={24}
                    onClick={(e) => handleToggleContextMenu(e, student)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex flex-wrap gap-3 items-center mb-[20px] justify-center w-full mt-3 border-t-2 py-2">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          First
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          Last
        </button>
      </div>

      {/* Custom Context Menu */}
      {contextMenu.visible && (
        <ul
          className="fixed bg-white border border-gray-300 shadow-lg rounded-md text-sm z-50 p-1 overflow-y-auto"
          style={{
            top: `${contextMenu.y}px`,
            left: `${contextMenu.x}px`,
          }}
        >
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 ${
                item.textClass || ""
              } ${item.borderBottom ? "border-b-[1px] border-gray-400" : ""}`}
              onClick={() => item.action(contextMenu.currentStudent)}
            >
              {item.icon}
              {item.label}
            </li>
          ))}
        </ul>
      )}

      {/* mobile context menu */}
      {toggleContextMenu && (
        <ul
          className="fixed bg-white border border-gray-300 shadow-lg rounded-md text-sm z-50 p-1"
          style={{
            top: `${contextMenu.y}px`,
            left: `${contextMenu.x - 130}px`,
          }}
        >
          {menuItems.map((item, index) => (
            <li
              key={index}
              className={`px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 ${
                item.textClass || ""
              } ${item.borderBottom ? "border-b-[1px] border-gray-400" : ""}`}
              onClick={() => item.action(contextMenu.currentStudent)}
            >
              {item.icon}
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Paginated;
