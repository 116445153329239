import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { PiStudent } from "react-icons/pi";
import { TbReport } from "react-icons/tb";
import { SiCoursera } from "react-icons/si";
import { FaBars, FaCar } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import { BiPowerOff } from "react-icons/bi";
import Logo from "./Logo";
import { AuthContext } from "../context/Contexts";
import { timestampToDate } from "../utils/timeStampToDate";
import { CgClose } from "react-icons/cg";

const TopNavBar = ({
  logout,
  handleToggleTopNav,
  toggleTopNav,
  handleToggleSideNav,
  toggleSideNav,
}) => {
  const { authUser } = useContext(AuthContext);
  const location = useLocation();

  const navItems = [
    { to: "/student", title: "Manage Students", icon: PiStudent, size: 24 },
    ...(authUser.role === "admin"
      ? [{ to: "/course", title: "Manage Courses", icon: SiCoursera, size: 20 }]
      : []),
    {
      to: "/financialReport",
      title: "Explore Reports",
      icon: TbReport,
      size: 24,
    },
    { to: "/vehicle", title: "Manage Vehicles", icon: FaCar, size: 24 },
  ];

  const [toggleUserPopup, setToggleUserPopup] = useState(false);

  const handleUserPopup = () => {
    setToggleUserPopup(!toggleUserPopup);
  };

  return (
    <nav className="w-full fixed top-0 py-1 flex items-center justify-between px-3 z-50">
      <div className="md:hidden flex">
        {toggleSideNav ? (
          <CgClose
            className="cursor-pointer text-red-500"
            size={26}
            onClick={handleToggleSideNav}
          />
        ) : (
          <FaBars
            className="text-[var(--primary-dark)] cursor-pointer"
            size={26}
            onClick={handleToggleSideNav}
          />
        )}
      </div>
      <div className="flex items-center md:gap-4 gap-1">
        <Logo />
        <div className="px-3 py-1 bg-gray-300 rounded-xl text-xs">v.2.0.1</div>
      </div>

      <div className="flex items-center gap-[70px] justify-center">
        <ul className="md:flex items-center hidden">
          {navItems.map(({ to, title, icon: Icon, size }, index) => {
            const isActive = location.pathname === to;
            return (
              <li key={index} title={title}>
                <Link to={to}>
                  <Icon
                    size={isActive ? size + 4 : size} // Increase size if active
                    className={isActive ? "bg-gray-300 p-1 rounded" : ""}
                  />
                </Link>
              </li>
            );
          })}
        </ul>

        {toggleTopNav && (
          <ul className="md:hidden items-center flex flex-col mt-[50px] absolute top-4 right-2 z-10 p-2 bg-slate-50 rounded-md border-[1px] border-slate-300">
            {navItems.map(({ to, title, icon: Icon, size }, index) => {
              const isActive = location.pathname === to;
              return (
                <li key={index} title={title}>
                  <Link to={to} onClick={handleToggleTopNav}>
                    <Icon
                      size={isActive ? size + 4 : size} // Increase size if active
                      className={isActive ? "bg-gray-300 p-1 rounded" : ""}
                    />
                  </Link>
                </li>
              );
            })}
          </ul>
        )}

        <div className="cursor-pointer flex items-center gap-2 relative">
          <div>
            <img
              src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png"
              alt="Profile Pic"
              className="w-[40px]"
              title="Your Account"
              onClick={handleUserPopup}
            />

            {toggleUserPopup && (
              <div className="absolute top-full right-0 bg-white p-1 w-[150px] mt-2 rounded-lg shadow-xl z-50 flex flex-col items-center gap-2">
                <img
                  src="https://cdn3.iconfinder.com/data/icons/business-avatar-1/512/11_avatar-512.png"
                  alt="Profile Pic"
                  className="w-[60px]"
                  title="Your Account"
                />

                <p className="text-sm font-semibold">{authUser.firstname}</p>
                <p className="text-xs">~{authUser.role}~</p>

                <p className="mt-3 text-xs font-bold">Logged at</p>
                <p className="text-xs">{timestampToDate(authUser?.ait).date}</p>
                <p className="text-xs">{timestampToDate(authUser?.ait).time}</p>

                <div
                  className="text-sm mt-2 cursor-pointer py-2 px-4 bg-red-600 text-white rounded-md font-semibold"
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            )}
          </div>

          <div
            className="md:flex hidden py-2 px-4 bg-red-600 text-white rounded-md font-semibold shadow-lg"
            title="Logout"
            onClick={logout}
          >
            <BiPowerOff size={20} />
          </div>
          <div className="md:hidden flex cursor-pointer">
            <CiMenuKebab size={30} color="blue" onClick={handleToggleTopNav} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopNavBar;
