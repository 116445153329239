import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import {
  FaBars,
  FaUser,
  FaBook,
  FaMoneyBill,
  FaCar,
  FaEnvelope,
  FaUsers,
  FaMobileAlt,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import { CgClose } from "react-icons/cg";
import { BsArrowUp } from "react-icons/bs";
import { Logo } from "../components";

const Welcome = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false);
  const websiteURL = "https://www.systemdevmw.com";

  const goToLogin = () => {
    navigate("/");
  };

  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const navItems = [
    { name: "Intro", link: "#intro" },
    { name: "System Features", link: "#features" },
    { name: "About Us", link: "#about" },
    { name: "Contact Us", link: "#contact" },
  ];

  const renderNavLinks = (isMobile = false) =>
    navItems.map((item) => (
      <li key={item.name}>
        <a
          href={item.link}
          onClick={handleToggleMenu}
          className={`font-semibold  md:text-lg ${
            pathname === item.link ? "text-blue-500 text-xl" : ""
          }`}
        >
          {item.name}
        </a>
      </li>
    ));

  const features = [
    {
      title: "Student Info Management",
      icon: <FaUser size={32} />,
      description:
        "Manage all student details, registration and progress information efficiently.",
      footer: "Keep student records organized",
    },
    {
      title: "Course Info Management",
      icon: <FaBook size={32} />,
      description:
        "Easily organize and manage courses offered by your driving school.",
      footer: "Streamline course management",
    },
    {
      title: "Fees Structure Management",
      icon: <FaMoneyBill size={32} />,
      description:
        "Define and manage fees for courses and other services provided by the school.",
      footer: "Control fee structures",
    },
    {
      title: "Staff Management",
      icon: <FaUsers size={32} />,
      description:
        "Manage staff profiles, assignments, and roles within the school.",
      footer: "Organize your team",
    },
    {
      title: "Vehicle Management",
      icon: <FaCar size={32} />,
      description:
        "Keep track of the school’s vehicle fleet and assign them for training sessions.",
      footer: "Monitor vehicle usage",
    },
    {
      title: "School Fees Management",
      icon: <FaMoneyBill size={32} />,
      description:
        "Track school fees payments, manage balances, and handle transactions.",
      footer: "Simplify financial tracking",
    },
    {
      title: "Cash Flow Management",
      icon: <FaMoneyBill size={32} />,
      description:
        "Manage the school’s financials with real-time cash flow tracking.",
      footer: "Monitor income and expenses",
    },
    {
      title: "Admin Email Notifications",
      icon: <FaEnvelope size={32} />,
      description:
        "Receive email notifications when data is added, even when out of the office.",
      footer: "Stay updated remotely",
    },
    {
      title: "Multi-User Access",
      icon: <FaUsers size={32} />,
      description: "Support for multiple users with role-based permissions.",
      footer: "Allow team collaboration",
    },
    {
      title: "Mobile & Desktop Compatibility",
      icon: <FaMobileAlt size={32} />,
      description: "Accessible on both mobile devices and desktop computers.",
      footer: "Use on any device",
    },
  ];

  return (
    <div className="w-full overflow-hidden relative">
      <a
        href="#top"
        className="fixed text-white bottom-0 right-0 z-10 bg-black p-2 rounded-full animate-pulse m-2"
        title="Go to top"
      >
        <BsArrowUp size={24} />
      </a>

      <img
        src="https://i.pinimg.com/originals/55/01/60/5501609ee45d514d1f2c4a63502045e2.gif"
        alt="Background Pic"
        className="object-cover w-full h-[100vh]"
      />

      <div
        id="top"
        className="flex w-full h-[70px] bg-black absolute top-0 z-10 text-white items-center justify-between px-3"
      >
        <Logo />
        <ul className="md:flex items-center hidden">{renderNavLinks()}</ul>

        <div className="md:hidden flex cursor-pointer">
          {!toggleMenu ? (
            <FaBars size={30} onClick={handleToggleMenu} />
          ) : (
            <CgClose size={30} onClick={handleToggleMenu} />
          )}
        </div>
      </div>

      {toggleMenu && (
        <ul className="md:hidden items-center px-2 flex flex-col border-2  border-black bg-white rounded-lg shadow-lg py-8 absolute top-[70px] right-2 z-10">
          {renderNavLinks(true)}
        </ul>
      )}

      <div
        id="intro"
        className="absolute w-full h-[98vh] bg-black/80 text-white top-0 flex justify-center flex-col items-center p-2"
      >
        <h1 className="md:text-4xl text-xl font-bold">Welcome to Pamseu IMS</h1>
        <div className="max-w-[500px] mt-8 text-center md:text-lg text-sm">
          Pamseu IMS is a powerful, user-friendly management system for driving
          schools, designed to streamline student registration, scheduling,
          payment tracking, and performance monitoring. It simplifies daily
          operations, reduces paperwork, and provides real-time insights to help
          schools run more efficiently.
        </div>

        <button
          className="flex items-center mt-[40px] md:text-lg"
          onClick={goToLogin}
        >
          Get Started <BiRightArrowAlt size={30} />
        </button>
      </div>

      <div
        id="features"
        className="md:px-[110px] px-[20px] py-[50px] relative mt-[-100px] bg-white md:rounded-[10%]  flex items-center justify-center flex-col"
      >
        <h1 className="md:text-3xl text-xl font-bold mb-8">System Features</h1>
        <hr className="w-full m-1" />
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md p-6 text-center flex flex-col items-center"
            >
              <div className="text-blue-500 mb-4">{feature.icon}</div>
              <h3 className="md:text-xl text-lg font-semibold mb-2">
                {feature.title}
              </h3>
              <p className="text-gray-600 mb-4">{feature.description}</p>
              <footer className="text-sm text-gray-400">
                {feature.footer}
              </footer>
            </div>
          ))}
        </div>
      </div>

      <div
        id="about"
        className="min-h-screen bg-gray-100 flex flex-col items-center p-6 mt-[-30px] m-2 border-2 border-gray-200"
      >
        {/* Page Header */}
        <header className="w-full max-w-4xl text-center py-10">
          <h1 className="md:text-3xl text-xl font-bold text-blue-600">
            About Pamseu IMS
          </h1>
          <p className="mt-4 text-gray-600 md:text-lg">
            Pamseu IMS is a powerful, user-friendly management system designed
            specifically for driving schools in Malawi. Our mission is to
            simplify school operations, reduce paperwork, and provide real-time
            insights to help schools run more efficiently.
          </p>
        </header>

        {/* Main Content */}
        <main className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-8 mb-10">
          <section className="mb-8">
            <h2 className="md:text-2xl text-lg font-semibold text-gray-800 mb-4">
              Our Mission
            </h2>
            <p className="text-gray-600">
              Our mission is to empower driving schools with the tools they need
              to streamline student registration, scheduling, payment tracking,
              and performance monitoring. Pamseu IMS is built to help schools
              focus more on training and less on administrative tasks.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="md:text-2xl text-lg font-semibold text-gray-800 mb-4">
              Why Choose Pamseu IMS?
            </h2>
            <p className="text-gray-600">
              Pamseu IMS offers comprehensive features tailored for driving
              schools, including student management, course organization, fee
              management, vehicle tracking, and more. Our system supports
              multiple users, ensuring seamless collaboration across teams while
              keeping data secure and accessible.
            </p>
          </section>

          {/* Call-to-Action Section */}
          <section className="flex flex-col items-center mt-8">
            <a
              href={"https://systemdevmw.com"}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-blue-600 hover:bg-blue-700 font-bold py-2 px-6 rounded-full"
            >
              Visit Our Website
            </a>
          </section>
        </main>
      </div>

      <div id="contact" className="min-h-screen flex flex-col items-center">
        {/* Page Header */}
        <header className="w-full max-w-4xl text-center py-10">
          <h1 className="md:text-4xl text-xl font-bold text-blue-600">
            Contact Us
          </h1>
          <p className="mt-4 text-gray-600 md:text-lg text-center px-5">
            Get in touch with Pamseu IMS. We’re here to help answer any
            questions you may have about our system and services.
          </p>
        </header>

        {/* Contact Details Section */}
        <main className="w-full md:max-w-4xl bg-white rounded-lg shadow-lg p-8 mb-10 m-2">
          <section className="mb-8">
            <h2 className="md:text-2xl text-lg font-semibold text-gray-800 mb-4">
              Contact Information
            </h2>
            <div className="space-y-4 text-gray-600">
              <div className="flex items-center">
                <FaPhoneAlt className="text-blue-600 mr-3" />
                <p>Phone: +265 884 799 203 / +265 996 570 252</p>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-blue-600 mr-3" />
                <p>
                  Email:{" "}
                  <a
                    href="mailto:mikelibamba@gmail.com"
                    className="text-blue-600 hover:underline"
                  >
                    info@systedevmw.com
                  </a>
                </p>
              </div>
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-blue-600 mr-3" />
                <p>Address: Lilongwe, Malawi</p>
              </div>
            </div>
          </section>

          {/* Map Section */}
          <section className="mb-8">
            <h2 className="md:text-2xl text-xl font-semibold text-gray-800 mb-4">
              Location
            </h2>
            <div className="w-full h-64 bg-gray-200 rounded-lg flex items-center justify-center">
              <p className="text-gray-500">Map Placeholder</p>
            </div>
            <p className="text-gray-500 text-sm mt-2">
              Our office is conveniently located in Lilongwe, Malawi.
            </p>
          </section>

          {/* Visit Website Button */}
          <section className="flex flex-col items-center mt-8">
            <a
              href={websiteURL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white bg-blue-600 hover:bg-blue-700 font-bold py-2 px-6 rounded-full"
            >
              Visit Our Website
            </a>
          </section>
        </main>

        {/* Social Media Links */}
        <footer className="text-center">
          <p className="text-gray-500 mb-4">Follow us on social media:</p>
          <div className="flex justify-center space-x-4 mb-6">
            <a
              href="https://web.facebook.com/profile.php?id=61565491921694"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700"
              aria-label="Facebook"
            >
              <FaFacebook size={24} />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-500"
              aria-label="Twitter"
            >
              <FaTwitter size={24} />
            </a>
            <a
              href="linkedin.com/in/mike-mhango-a960b3219"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-800"
              aria-label="LinkedIn"
            >
              <FaLinkedin size={24} />
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Welcome;
