import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/Contexts";
import { Routes, useNavigate, Route, useLocation } from "react-router-dom";
import { NotFound, SideNavBar, TopNavBar } from "../components";
import {
  Course,
  Dashboard,
  Expense,
  FeesBalance,
  FinancialReport,
  Income,
  Payment,
  Receipts,
  Staff,
  Student,
  Test,
  Vehicle,
} from "./";
import { toast } from "react-toastify";

const Home = () => {
  const { authUser, setAuthUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [toggleSideNav, setToggleSideNav] = useState(false);
  const [toggleTopNav, setToggleTopNav] = useState(false);

  useEffect(() => {
    if (!authUser.loginStatus) {
      navigate("/login");
    } else {
      toast.success(`Welcome ... ${authUser.firstname} !`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [authUser]);

  const logout = () => {
    localStorage.removeItem("loginToken");
    setAuthUser((authUser) => ({
      ...authUser,
      loginStatus: false,
    }));
    navigate("/login"); // Optionally navigate to login after logout
  };

  const handleToggleTopNav = () => {
    setToggleTopNav(!toggleTopNav);
  };

  const handleToggleSideNav = () => {
    setToggleSideNav(!toggleSideNav);
  };

  return (
    <div className="flex flex-col w-full">
      <TopNavBar
        logout={logout}
        handleToggleTopNav={handleToggleTopNav}
        toggleTopNav={toggleTopNav}
        handleToggleSideNav={handleToggleSideNav}
        toggleSideNav={toggleSideNav}
      />

      <div className="flex relative top-[55px]">
        <SideNavBar
          handleToggleSideNav={handleToggleSideNav}
          toggleSideNav={toggleSideNav}
        />

        <div className="fixed md:w-[80%] w-full md:left-[310px] md:h-[88vh] h-[85vh] overflow-auto flex-col flex mt-[40px] mb-[50px] md:p-[0px] p-2">
          <Routes>
            <Route path="/student" element={<Student />} />
            <Route path="/course" element={<Course />} />
            <Route path="/receipts" element={<Receipts />} />
            <Route path="/income" element={<Income />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/expense" element={<Expense />} />
            <Route path="/feesBalance" element={<FeesBalance />} />
            <Route path="/financialReport" element={<FinancialReport />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/vehicle" element={<Vehicle />} />
            <Route path="/test" element={<Test />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
