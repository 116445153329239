import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApi from "../hooks/useApi";
import { apiUrl } from "../utils/links";
import { VehicleForm, Loading, Modal, VehicleCards } from "../components";

const Vehicle = () => {
  const { data, error, setData, loading, refetch } = useApi();

  useEffect(() => {
    const loadVehicle = async () => {
      await refetch(`${apiUrl}/vehicles`, "GET", null, {
        headers: { loginToken: localStorage.getItem("loginToken") },
      });
    };

    loadVehicle();
  }, []);

  return (
    <div className="w-full flex h-full flex-col items-center relative gap-3">
      <div className="self-start">
        <Link to={"/dashboard"} className="text-yellow-700 py-1">
          Dashboard
        </Link>
        {" > "}
        Vehicles
      </div>
      <div className="text-lg font-bold mt-[20px]">Manage Vehicles</div>
      <hr className="my-4 w-full" />
      <div className="flex flex-wrap items-center flex-col w-full">
        <div className="w-full">
          {loading ? (
            <Loading />
          ) : error ? (
            <div className="w-full text-center text-lg text-red-600 mt-[40px]">
              Oops! {error}
            </div>
          ) : data ? (
            <VehicleCards data={data} setData={setData} />
          ) : (
            <div className="w-full text-center mt-[40px] ">
              <p className="text-lg">Nothing to display here.</p>
              <p className="mt-[15px] italic">Please add a vehicle</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
