import React, { useEffect } from "react";
import { toKwacha } from "./../../utils/toKwacha";
import html2pdf from "html2pdf.js";
import { readableTimestamp } from "./../../utils/readableTimeStamps";
import { QuickPassLogo } from "..";

const Report = ({
  receipts,
  income,
  payments,
  totalReceipts,
  totalPayments,
  totalBanked,
  cashInHand,
  gvtFees,
  dates,
  closeModal,
}) => {
  var opt = {
    margin: [15, 15],
    filename: "pdfFileName.pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2, letterRendering: true },
    jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
    pagebreak: { mode: ["avoid-all", "css", "legacy"] },
  };

  const print = (id) => {
    const element = document.getElementById(id);
    html2pdf(element, {
      margin: 2, // Reduced margin for fitting more content
      filename: `cash-flow-${readableTimestamp()}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 }, // Higher scale for quality
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // Portrait A4
    });
  };

  useEffect(() => {
    print("report-content");
    closeModal();
  }, []);

  return (
    <div className="container mx-auto p-6 gap-6 flex flex-col">
      <button
        className="flex items-center justify-center gap-1 flex-wrap"
        onClick={() => print("report-content")}
      >
        Report has been downloaded successfully. <br />
        This is just a preview of the report go to downloads and access the pdf!
      </button>

      {/* Report Content */}
      <div className="w-full flex flex-col" id="report-content">
        <div className="mb-3 flex md:justify-between justify-center items-center flex-wrap gap-3">
          <div>
            <QuickPassLogo />
            <p className="text-sm mt-2">
              Date range:{" "}
              {dates ? `From ${dates.startDate} to ${dates.endDate}` : "N/A"}
            </p>
            <p className="text-sm mt-1">
              Printed on: {new Intl.DateTimeFormat("en-GB").format(new Date())}
            </p>
          </div>
          <h2 className="md:text-2xl font-bold mb-4 text-center">
            Cash Flow Report
          </h2>
        </div>

        {/* Receipts Table */}
        <div className="bg-white shadow-md rounded-lg overflow-auto">
          <h2 className="text-md font-semibold text-center p-2 text-blue-500">
            Sales
          </h2>
          <table className="w-full table-auto">
            <thead className="bg-gray-100 text-sm">
              <tr>
                <th className="px-4 py-2">R. No</th>
                <th className="px-4 py-2">Source</th>
                <th className="px-4 py-2">Amount</th>
                <th className="px-4 py-2">Mode</th>
                <th className="px-4 py-2">Date</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {receipts.map((receipt) => (
                <tr key={receipt.id} className="border-t">
                  <td className="px-4 py-2">{receipt?.receiptNo}</td>
                  <td className="px-4 py-2">{receipt?.Student?.fullname}</td>
                  <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                  <td className="px-4 py-2">{receipt?.modeOfPayment}</td>
                  <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className="bg-gray-200 font-bold p-2">
                  Government fees
                </td>
              </tr>
              {gvtFees.map((receipt) => (
                <tr key={receipt.id} className="border-t">
                  <td className="px-4 py-2">{receipt?.receiptNo}</td>
                  <td className="px-4 py-2">
                    {receipt?.Student?.fullname} ({receipt?.paymentOf})
                  </td>
                  <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                  <td className="px-4 py-2">{receipt?.modeOfPayment}</td>
                  <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={5} className="bg-gray-200 font-bold p-2">
                  Other income
                </td>
              </tr>
              {income.map((receipt) => (
                <tr key={receipt.id} className="border-t">
                  <td className="px-4 py-2">{receipt?.receiptNo}</td>
                  <td className="px-4 py-2">{receipt?.mirage}</td>
                  <td className="px-4 py-2">{toKwacha(receipt?.amount)}</td>
                  <td className="px-4 py-2">{receipt?.mode}</td>
                  <td className="px-4 py-2">{receipt?.dateOfPayment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Payments Table */}
        <div className="bg-white shadow-md rounded-lg overflow-auto">
          <h2 className="text-md font-semibold text-center p-2 text-red-500">
            Payments
          </h2>
          <table className="w-full table-auto text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-1">Description</th>
                <th className="px-4 py-1">Amount</th>
                <th className="px-4 py-1">Mode</th>
                <th className="px-4 py-1">Date</th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {payments.map((payment) => (
                <tr key={payment.id} className="border-t">
                  <td className="px-4 py-2">{payment?.mirage}</td>
                  <td className="px-4 py-2">{toKwacha(payment?.amount)}</td>
                  <td className="px-4 py-2">{payment?.mode}</td>
                  <td className="px-4 py-2">{payment?.dateOfPayment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Summary Section */}
        <div className="bg-gray-200 p-6 mt-8 rounded-lg shadow-md">
          <h2 className="text-lg font-semibold mb-4 text-center">
            Report Summary
          </h2>
          <div className="flex flex-col md:flex-row justify-around text-center md:gap-1 gap-[30px] ">
            <div className="mb-2">
              <p className="text-lg font-medium">Total Sales</p>
              <p className="text-xl font-semibold text-blue-600">
                {toKwacha(totalReceipts)}
              </p>
            </div>
            <div className="mb-2">
              <p className="text-lg font-medium">Total Payments</p>
              <p className="text-lg font-semibold text-red-600">
                {toKwacha(totalPayments)}
              </p>
            </div>
            <div className="mb-2">
              <p className="text-lg font-medium">Banked</p>
              <p className="text-lg font-semibold text-cyan-600">
                {toKwacha(totalBanked)}
              </p>
            </div>
            <div className="mb-2">
              <p className="text-lg font-medium">Cash in Hand</p>
              <p
                className={`text-lg font-semibold ${
                  cashInHand >= 0 ? "text-green-600" : "text-red-600"
                }`}
              >
                {toKwacha(cashInHand)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
